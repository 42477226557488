<template>
    <div class="bg-white py-7 py-md-15" v-if="buyerCountryIsActive && listings">
    <div class="mt-3 container">
        <div class="d-flex justify-content-between mb-6 p-0">
            <div>
                <h2 class="text-left text-dark">{{ $t("Buy.CarCarousel.LatestListings", [destinationCountry && destinationCountry.Name || country.Name]) }}</h2>
                <div id='car-preview-fees-checkbox' class="mt-5 mb-0 d-flex justify-content-left align-items-center" style="width:fit-content;">
                    <v-checkbox
                        id="clearancePreviewCheckbox"
                        class="mr-2"
                        v-model="includeFees"
                        hide-details="auto"
                        :label="$t('Overview.fees.included')"
                        >
                    </v-checkbox>
                    <b-icon icon="info-circle" />
                </div>
            <b-tooltip
            target="car-preview-fees-checkbox"
                    triggers="hover"
                    placement="bottom"
                    variant="dark"
                    ><span style="font-size:10px;">
                      {{ $t('Buy.Search.PopupMessage') }}
                    </span></b-tooltip
                  >
            </div>
            <div class="btn btn-primary rounded-pill align-self-end d-none d-sm-block" style="height:fit-content;">
                <router-link :to="$t('Buy.Link.Search')" pill class="px-3 text-white d-flex justify-content-center align-items-center">
                    {{ $t("Buy.Content.ViewAll") }}
                    <ChevronRight style="width:16px"/>
                </router-link>
            </div>
        </div>
        
      <!-- <div class="d-flex flex-column flex-lg-row gap-md-10 rounded shadow-sm bg-white p-sm-4 p-3"> -->
        <div class="overflow-auto">
            <div id="carousel-container" class="w-100 position-relative">
                <div id="carousel" class="hide-scrollbar d-flex mt-lg-0 gap-3 py-lg-0 overflow-auto">
                    <!-- <div class="search-card " v-for="carDetail in listings"
                        :key="carDetail.ListingId">
                        <TheHomepageCards :carDetail="carDetail"/>
                    </div> -->
                    <div class="card-wrapper">
                        <div
                          class="item"
                          v-for="carDetail in listings"
                          :key="carDetail.ListingId"
                        >
                            <TheCarCard :carDetail="carDetail" :price="carDetail.Price" :includeFees="includeFees"/>
                        </div>
                        <!-- <div class="d-flex justify-content-center align-items-center" style="width:175px;">
                            <router-link :to="$t('Buy.Link.Search')" pill class="btn btn-primary rounded family-inter w-100 h-100">
                                <span>{{ $t("Buy.Content.SeeAll") }}</span>
                            </router-link>
                        </div> -->
                    </div>
                </div>
                <button class="ml-3 carousel-left-arrow btn bg-white text-primary shadow-sm rounded-circle shadow-sm p-0 d-none" @click="scrollLeft()">
                   <ChevronLeft size="35"/>
                    <span class="sr-only">Previous</span>
                </button>
                <button class="mr-3 carousel-right-arrow btn bg-white text-primary shadow-sm rounded-circle shadow-sm p-0 d-none d-md-block" @click="scrollRight()">
                    <ChevronRight size="35"/>
                    <span class="sr-only">Next</span>
                </button>
            </div>
        </div>
      <!-- </div> -->
          <div class="mt-4 btn btn-primary rounded-pill align-self-end d-sm-none" style="height:fit-content;">
                      <router-link :to="$t('Buy.Link.Search')" pill class="px-3 text-white d-flex justify-content-center align-items-center">
                          {{ $t("Buy.Content.ViewAll") }}
                          <ChevronRight style="width:16px"/>
                      </router-link>
                  </div>
    </div>
</div>
  </template>
  
  <script>
  import axios from 'axios';
//   import TheHomepageCards from './TheHomepageCards.vue';
  import TheCarCard from '@/components/global/TheCarCard.vue';
  import { mapGetters } from "vuex";
  import includeFees from "@/components/mixins/includeFees.js"
  import ChevronRight from 'vue-material-design-icons/ChevronRight.vue';
  import ChevronLeft from 'vue-material-design-icons/ChevronLeft.vue';
  
  export default {
      data() {
          return {
              listings: null,
              retrievingData: false
          }
      },
      watch: {
        includeFees: function () {
            this.getListings();
        },
        userCurrency() {
            this.getListings();
        },
        destinationCountry() {
            this.getListings();
        },
        country() {
            this.getListings();
        }
      },
      components: {
        // TheHomepageCards,
        TheCarCard,
        ChevronRight,
        ChevronLeft
      },
      mixins: [includeFees],
      computed: {
          ...mapGetters([
            "country"
        ]),
        destinationCountry() {
            return this.$store.state.search.destinationCountry;
        },
        buyerCountryIsActive() {
            return (this.$store.state.search.destinationCountry && this.$store.state.search.destinationCountry.CanBuy) || this.country.CanBuy
        },
        userCurrency() {
            return this.$store.state.user.currency
        }
      },
      async mounted() {
          try {
              if (this.buyerCountryIsActive) this.getListings();
          } catch (err) {
              console.log(err)
          }
      },
      methods: {
          async getListings() {
            if (!this.retrievingData && this.buyerCountryIsActive) {
                this.retrievingData = true;
                const deliveryCountry = this.destinationCountry ? this.destinationCountry.Code : this.country.Code;
                const response = await axios.post("/Listing/ListingSearch", {
                    DeliveryCountryCode: deliveryCountry,
                    PageSize: 6,
                    PageNo: 1,
                    Prefixes: null,
                    Tags: null,
                    Sort: 6
                });
                this.listings = response.data.Cards
                this.retrievingData = false
            }
          },
          scrollLeft() {
            let carousel = document.getElementById('carousel')
            let carouselWidth = carousel.getBoundingClientRect().width;
            carousel.scrollBy({left: -carouselWidth});

            setTimeout(() => {
                let rightArrow = document.getElementsByClassName('carousel-right-arrow')[0]
                let leftArrow = document.getElementsByClassName('carousel-left-arrow')[0]
                if (carousel.scrollLeft < carousel.scrollWidth - carousel.clientWidth) rightArrow.classList.add('d-md-block')
                if (carousel.scrollLeft <= 0) leftArrow.classList.remove('d-md-block')
            }, 700);
        },
        scrollRight() {
            let carousel = document.getElementById('carousel')
            let carouselWidth = carousel.getBoundingClientRect().width;
            document.getElementById('carousel').scrollBy({left: carouselWidth});
            
            setTimeout(() => {
                let rightArrow = document.getElementsByClassName('carousel-right-arrow')[0]
                let leftArrow = document.getElementsByClassName('carousel-left-arrow')[0]
                if (carousel.scrollLeft > 0) leftArrow.classList.add('d-md-block')
                else leftArrow.classList.remove('d-md-block')
                if (carousel.scrollLeft >= carousel.scrollWidth - carousel.clientWidth) rightArrow.classList.remove('d-md-block')
            }, 700);
          }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  #carousel {
    scroll-behavior: smooth;
  }
  .carousel-left-arrow {
    position: absolute;
    left: 0;
    top: 45%;
}
.carousel-right-arrow {
    position: absolute;
    right: 0;
    top: 45%;
  }
  .hide-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .hide-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    .card-wrapper {
  display: flex;
  flex-direction: column;
  column-gap: 16px;
  row-gap: 16px;
  flex-direction: row;
  .item {
    width: 350px;
    > .card {
        height:unset!important;
    }
  }
}

.section1button {
    padding: 16px 52px;
    box-shadow: none;
    color: white !important;
    border-radius: 45px;
    text-decoration: none !important;
    transition: 0.3s;
    @media screen and (min-width:960px) {
        max-width: 260px;
    }
}

#car-preview-fees-checkbox {
    > div {
        margin:0;
        padding:0;
    }    
}

.justify-content-md-between-custom {
    @media (min-width: 960px){
    justify-content: space-between !important;
}
}
  </style>